import './App.less';
import {Provider as InversifyProvider} from 'inversify-react';
import {Provider, useSelector} from 'react-redux'
import {store} from './redux/store';
import RoutingContainer, {RoutingPaths} from "./pages/routing/routingContainer";
import React, {useEffect} from "react";
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import ApiService from "./services/apiService";
import SiderMenu from './pages/components/siderMenu/siderMenu';
import HeaderContainer from './pages/components/header/headerContainer';
import FooterContainer from './pages/components/footer/footerContainer';
import {CrudBreadcrumb, CrudLayout} from 'digimuth-components/dist';
import {Container} from 'inversify';
import {AuthProvider, AuthProviderProps, initUserManager, useAuth, UserManager} from 'oidc-react';
import {createBrowserHistory} from "history";
import {ConfigProvider, message} from 'antd';
import plPL from 'antd/es/locale/pl_PL';
import dayjs from "dayjs";
import {getCurrentUser} from "./redux/applicationUserSlice";

dayjs.locale('pl');

const authSettings: AuthProviderProps = {
    authority: process.env.REACT_APP_URL,
    clientId: "Digimuth.Talemax.Dashboard.Web",
    redirectUri: process.env.REACT_APP_URL,
    autoSignIn: false,
    automaticSilentRenew: true,
    scope: "openid Digimuth.Talemax.Dashboard.WebAPI profile offline_access"
};

export const userManager: UserManager | undefined = initUserManager(authSettings);

function App() {

    const container = new Container();
    const history = createBrowserHistory();
    container.bind(ApiService).toSelf().inSingletonScope();

    return (
        // @ts-ignore
        <AuthProvider
            userManager={userManager}
            onSignIn={u => {
                const userState = u?.state as any;
                const fromState = userState.targetPath;
                const targetUrl = fromState ? fromState : "/";
                history.replace(targetUrl);
            }}
            autoSignIn={false}>
            <Provider store={store}>
                <InversifyProvider container={container}>
                    {/* @ts-expect-error */}
                    <HistoryRouter history={history}>
                        <ConfigProvider locale={plPL}>
                            <Layout/>
                        </ConfigProvider>
                    </HistoryRouter>
                </InversifyProvider>
            </Provider>
        </AuthProvider>
    )
}

const events = [
    "mousedown",
    "mousemove",
    "wheel",
    "keydown",
    "touchstart",
    "scroll"
];

function Layout() {
    const auth = useAuth();
    const currentUser = useSelector(getCurrentUser);
    const intervalRef = React.useRef<any>(null);
    const inactivityTime = 1000 * 60 * 3; //3 minutes

    useEffect(() => {
        if (!currentUser) return;

        const autoLogout = currentUser?.autoLogout ?? true;

        const onCloseLogout = async () => {
            if (!autoLogout) return;
            userManager?.signoutSilent();
        };

        const resetTimer = () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            intervalRef.current = setTimeout(async () => {
                if (!autoLogout) return;
                message.info("Natępuje wylogowanie z powodu braku aktywności");
                await auth.signOut();
                await auth.signOutRedirect();
            }, inactivityTime);
        }

        events.forEach((event) => window.addEventListener(event, resetTimer));
        window.addEventListener("beforeunload", onCloseLogout, {capture: true});
        window.addEventListener("unload", onCloseLogout, {capture: true});
        resetTimer();

        return () => {
            clearInterval(intervalRef.current);
            events.forEach((event) => window.removeEventListener(event, resetTimer));
            window.removeEventListener("beforeunload", onCloseLogout, {capture: true});
            window.removeEventListener("unload", onCloseLogout, {capture: true});
        }
    }, [currentUser]);

    return <CrudLayout
        breadcrumb={<CrudBreadcrumb routingDefinitions={RoutingPaths}/>}
        siderMenu={<SiderMenu/>}
        header={<HeaderContainer/>}
        siderFullHeight={true}
        footerContent={<FooterContainer/>}
        showSider={!!auth.userData}
    >
        <RoutingContainer/>
    </CrudLayout>;
}

export default App;
