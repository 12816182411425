/* tslint:disable */
/* eslint-disable */
/**
 * Digimuth.Talemax.Dashboard.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationUserActivationDto
 */
export interface ApplicationUserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActivationDto
     */
    token?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserDetailsDto
 */
export interface ApplicationUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ApplicationUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ApplicationUserDetailsDto
     */
    role?: ApplicationUserRole;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserDetailsDto
     */
    availableDevicesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserDetailsDto
     */
    emailAddressesList?: Array<string> | null;
    /**
     * 
     * @type {DeviceInactivityConfigurationDto}
     * @memberof ApplicationUserDetailsDto
     */
    deviceInactivityConfiguration?: DeviceInactivityConfigurationDto;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    sendMailAfterCashRemoval?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDetailsDto
     */
    cassetteFilling?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDetailsDto
     */
    address?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDetailsDto
     */
    autoLogout?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserDto
 */
export interface ApplicationUserDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserDto
     */
    autologout?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationUserMinimalDto
 */
export interface ApplicationUserMinimalDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserMinimalDto
     */
    userName?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserRegisterDto
 */
export interface ApplicationUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    withInvitation?: boolean;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ApplicationUserRegisterDto
     */
    role?: ApplicationUserRole;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicationUserRegisterDto
     */
    availableDevicesIds?: Array<number> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserRegisterDto
     */
    emailAddressesList?: Array<string> | null;
    /**
     * 
     * @type {DeviceInactivityConfigurationDto}
     * @memberof ApplicationUserRegisterDto
     */
    deviceInactivityConfiguration?: DeviceInactivityConfigurationDto;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserRegisterDto
     */
    cassetteFilling?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    sendMailAfterCashRemoval?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserRegisterDto
     */
    address?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserRegisterDto
     */
    autoLogout?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum ApplicationUserRole {
    Admin = 'Admin',
    ReportingSoftware = 'ReportingSoftware',
    Readonly = 'Readonly',
    DeviceAdmin = 'DeviceAdmin'
}

/**
 * 
 * @export
 * @interface CassetteContentDto
 */
export interface CassetteContentDto {
    /**
     * 
     * @type {number}
     * @memberof CassetteContentDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CassetteContentDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CassetteContentDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof CassetteContentDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CassetteContentDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CassetteContentDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof CassetteContentDto
     */
    fillLevel?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof CassetteContentDto
     */
    sum?: { [key: string]: number; } | null;
    /**
     * 
     * @type {Array<DenominationInfoDto>}
     * @memberof CassetteContentDto
     */
    denominationDetails?: Array<DenominationInfoDto> | null;
}
/**
 * 
 * @export
 * @interface CassetteContentMinimalDto
 */
export interface CassetteContentMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof CassetteContentMinimalDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof CassetteContentMinimalDto
     */
    fillLevel?: number;
}
/**
 * 
 * @export
 * @interface ConfigurationDto
 */
export interface ConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationDto
     */
    configuration?: string | null;
}
/**
 * 
 * @export
 * @interface DenominationInfoDto
 */
export interface DenominationInfoDto {
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    nominalValue?: number;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    count?: number;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    containerNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    direction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DenominationInfoDto
     */
    fits?: number;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    unfits?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    suspicious?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DenominationInfoDto
     */
    counterFits?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum DeviceInactivity {
    Saturday = 'Saturday',
    Sunday = 'Sunday',
    Weekend = 'Weekend'
}

/**
 * 
 * @export
 * @interface DeviceInactivityConfigurationDto
 */
export interface DeviceInactivityConfigurationDto {
    /**
     * 
     * @type {string}
     * @memberof DeviceInactivityConfigurationDto
     */
    from?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceInactivityConfigurationDto
     */
    to?: string | null;
    /**
     * 
     * @type {DeviceInactivity}
     * @memberof DeviceInactivityConfigurationDto
     */
    inactivityDays?: DeviceInactivity;
}
/**
 * 
 * @export
 * @interface LogDetailsDto
 */
export interface LogDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof LogDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LogDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LogDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    timestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    level?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    messageTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    renderedMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDetailsDto
     */
    exception?: string | null;
}
/**
 * 
 * @export
 * @interface LogDto
 */
export interface LogDto {
    /**
     * 
     * @type {number}
     * @memberof LogDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof LogDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LogDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    timestamp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    level?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    messageTemplate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    renderedMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LogDto
     */
    exception?: string | null;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginDto
     */
    rememberMe?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OperationType {
    Add = 'Add',
    Remove = 'Remove',
    Replace = 'Replace',
    Move = 'Move',
    Copy = 'Copy',
    Test = 'Test',
    Invalid = 'Invalid'
}

/**
 * 
 * @export
 * @interface ReportingUserDepositReportDto
 */
export interface ReportingUserDepositReportDto {
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDepositReportDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDepositReportDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDepositReportDto
     */
    depositDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDepositReportDto
     */
    sum?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDepositReportDto
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @interface ReportingUserDetailsDto
 */
export interface ReportingUserDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserDetailsDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<ApplicationUserRole>}
     * @memberof ReportingUserDetailsDto
     */
    roles?: Array<ApplicationUserRole> | null;
    /**
     * 
     * @type {DeviceInactivityConfigurationDto}
     * @memberof ReportingUserDetailsDto
     */
    deviceInactivityConfiguration?: DeviceInactivityConfigurationDto;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsDto
     */
    password?: string | null;
    /**
     * 
     * @type {Array<TransactionWithCassetteDetailsDto>}
     * @memberof ReportingUserDetailsDto
     */
    transactions?: Array<TransactionWithCassetteDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface ReportingUserDetailsReducedDto
 */
export interface ReportingUserDetailsReducedDto {
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsReducedDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsReducedDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsReducedDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserDetailsReducedDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {Array<CassetteContentDto>}
     * @memberof ReportingUserDetailsReducedDto
     */
    lastTransactionCassetteContent?: Array<CassetteContentDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    configuration?: string | null;
    /**
     * 
     * @type {DeviceInactivityConfigurationDto}
     * @memberof ReportingUserDetailsReducedDto
     */
    deviceInactivityConfiguration?: DeviceInactivityConfigurationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingUserDetailsReducedDto
     */
    emailAddressesList?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDetailsReducedDto
     */
    cassetteFilling?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDetailsReducedDto
     */
    address?: string | null;
}
/**
 * 
 * @export
 * @interface ReportingUserDto
 */
export interface ReportingUserDto {
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDto
     */
    userName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserDto
     */
    averageFillLevel?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserDto
     */
    sendMailAfterCashRemoval?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserDto
     */
    lastTransactionDate?: string | null;
}
/**
 * 
 * @export
 * @interface ReportingUserRegisterDto
 */
export interface ReportingUserRegisterDto {
    /**
     * 
     * @type {number}
     * @memberof ReportingUserRegisterDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserRegisterDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserRegisterDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserRegisterDto
     */
    emailConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    password?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserRegisterDto
     */
    withInvitation?: boolean;
    /**
     * 
     * @type {ApplicationUserRole}
     * @memberof ReportingUserRegisterDto
     */
    role?: ApplicationUserRole;
    /**
     * 
     * @type {number}
     * @memberof ReportingUserRegisterDto
     */
    cassetteFilling?: number | null;
    /**
     * 
     * @type {DeviceInactivityConfigurationDto}
     * @memberof ReportingUserRegisterDto
     */
    deviceInactivityConfiguration?: DeviceInactivityConfigurationDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportingUserRegisterDto
     */
    emailAddressesList?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReportingUserRegisterDto
     */
    sendMailAfterCashRemoval?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReportingUserRegisterDto
     */
    address?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionCashRemovalReportDto
 */
export interface TransactionCashRemovalReportDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionCashRemovalReportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionCashRemovalReportDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    type?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionCashRemovalReportDto
     */
    cassetteFillAverage?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionCashRemovalReportDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCashRemovalReportDto
     */
    reportedByUsername?: string | null;
    /**
     * 
     * @type {Array<CassetteContentMinimalDto>}
     * @memberof TransactionCashRemovalReportDto
     */
    cassetteContents?: Array<CassetteContentMinimalDto> | null;
}
/**
 * 
 * @export
 * @interface TransactionCreateDto
 */
export interface TransactionCreateDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof TransactionCreateDto
     */
    reportedByUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {TransactionDto}
     * @memberof TransactionCreateDto
     */
    parentTransaction?: TransactionDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionCreateDto
     */
    parentTransactionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionCreateDto
     */
    originalFileContents?: string | null;
    /**
     * 
     * @type {Array<DenominationInfoDto>}
     * @memberof TransactionCreateDto
     */
    denominationDetails?: Array<DenominationInfoDto> | null;
    /**
     * 
     * @type {Array<CassetteContentDto>}
     * @memberof TransactionCreateDto
     */
    cassetteContents?: Array<CassetteContentDto> | null;
}
/**
 * 
 * @export
 * @interface TransactionDetailsDto
 */
export interface TransactionDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof TransactionDetailsDto
     */
    reportedByUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {TransactionDto}
     * @memberof TransactionDetailsDto
     */
    parentTransaction?: TransactionDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionDetailsDto
     */
    parentTransactionId?: number | null;
    /**
     * 
     * @type {Array<DenominationInfoDto>}
     * @memberof TransactionDetailsDto
     */
    denominationDetails?: Array<DenominationInfoDto> | null;
    /**
     * 
     * @type {Array<CassetteContentDto>}
     * @memberof TransactionDetailsDto
     */
    cassetteContents?: Array<CassetteContentDto> | null;
    /**
     * 
     * @type {Array<CassetteContentDto>}
     * @memberof TransactionDetailsDto
     */
    cassetteContentsBefore?: Array<CassetteContentDto> | null;
    /**
     * 
     * @type {Array<TransactionDto>}
     * @memberof TransactionDetailsDto
     */
    transactions?: Array<TransactionDto> | null;
}
/**
 * 
 * @export
 * @interface TransactionDto
 */
export interface TransactionDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof TransactionDto
     */
    reportedByUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {TransactionDto}
     * @memberof TransactionDto
     */
    parentTransaction?: TransactionDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionDto
     */
    parentTransactionId?: number | null;
}
/**
 * 
 * @export
 * @interface TransactionExchangeReportDto
 */
export interface TransactionExchangeReportDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionExchangeReportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionExchangeReportDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionExchangeReportDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionExchangeReportDto
     */
    userId?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionMinimalDto
 */
export interface TransactionMinimalDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionMinimalDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    reportedByUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    direction?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionMinimalDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionMinimalDto
     */
    userId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionMinimalDto
     */
    parentTransactionId?: number | null;
}
/**
 * 
 * @export
 * @interface TransactionUpdateDto
 */
export interface TransactionUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof TransactionUpdateDto
     */
    reportedByUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {TransactionDto}
     * @memberof TransactionUpdateDto
     */
    parentTransaction?: TransactionDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionUpdateDto
     */
    parentTransactionId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionUpdateDto
     */
    originalFileContents?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionWithCassetteDetailsDto
 */
export interface TransactionWithCassetteDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    createdById?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    updatedById?: number | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    number?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    currency?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    method?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    direction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithCassetteDetailsDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    reportedByUserId?: number;
    /**
     * 
     * @type {ApplicationUserMinimalDto}
     * @memberof TransactionWithCassetteDetailsDto
     */
    reportedByUser?: ApplicationUserMinimalDto;
    /**
     * 
     * @type {TransactionDto}
     * @memberof TransactionWithCassetteDetailsDto
     */
    parentTransaction?: TransactionDto;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithCassetteDetailsDto
     */
    parentTransactionId?: number | null;
    /**
     * 
     * @type {Array<CassetteContentDto>}
     * @memberof TransactionWithCassetteDetailsDto
     */
    cassetteContents?: Array<CassetteContentDto> | null;
}
/**
 * 
 * @export
 * @interface TransactionWithdrawalReportDto
 */
export interface TransactionWithdrawalReportDto {
    /**
     * 
     * @type {number}
     * @memberof TransactionWithdrawalReportDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalReportDto
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionWithdrawalReportDto
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionWithdrawalReportDto
     */
    userId?: string | null;
}

/**
 * ApplicationUserApi - axios parameter creator
 * @export
 */
export const ApplicationUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost: async (applicationUserActivationDto?: ApplicationUserActivationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActivationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConfigurationDto} [configurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserConfigurationPost: async (configurationDto?: ConfigurationDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configurationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {string} [selectedDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDepositReportGet: async (reportingUserId?: number, selectedDate?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/depositReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (reportingUserId !== undefined) {
                localVarQueryParameter['reportingUserId'] = reportingUserId;
            }

            if (selectedDate !== undefined) {
                localVarQueryParameter['selectedDate'] = selectedDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdDelete', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPatch', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut: async (id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdPut', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost: async (id: number, applicationUserRole?: Array<ApplicationUserRole>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserIdRolesPost', 'id', id)
            const localVarPath = `/api/ApplicationUser/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRole, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost: async (applicationUserRegisterDto?: ApplicationUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/reporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiApplicationUserReportingIdGet', 'id', id)
            const localVarPath = `/api/ApplicationUser/reporting/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingPost: async (reportingUserRegisterDto?: ReportingUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/reporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportingUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingPut: async (id?: number, reportingUserRegisterDto?: ReportingUserRegisterDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/reporting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportingUserRegisterDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet: async (email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ApplicationUser/resend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationUserApi - functional programming interface
 * @export
 */
export const ApplicationUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationUserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserActivatePost(applicationUserActivationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConfigurationDto} [configurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserConfigurationPost(configurationDto?: ConfigurationDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserConfigurationPost(configurationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {string} [selectedDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserDepositReportGet(reportingUserId?: number, selectedDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportingUserDepositReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserDepositReportGet(reportingUserId, selectedDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdPut(id, applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserIdRolesPost(id, applicationUserRole, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserPost(applicationUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserReportingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReportingUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserReportingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserReportingIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportingUserDetailsReducedDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserReportingIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserReportingPost(reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportingUserDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserReportingPost(reportingUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserReportingPut(id?: number, reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserReportingPut(id, reportingUserRegisterDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiApplicationUserResendGet(email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiApplicationUserResendGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationUserApi - factory interface
 * @export
 */
export const ApplicationUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationUserApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConfigurationDto} [configurationDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserConfigurationPost(configurationDto?: ConfigurationDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserConfigurationPost(configurationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {string} [selectedDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserDepositReportGet(reportingUserId?: number, selectedDate?: string, options?: any): AxiosPromise<Array<ReportingUserDepositReportDto>> {
            return localVarFp.apiApplicationUserDepositReportGet(reportingUserId, selectedDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserGet(options?: any): AxiosPromise<Array<ApplicationUserDto>> {
            return localVarFp.apiApplicationUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdGet(id: number, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<ApplicationUserRole>} [applicationUserRole] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any): AxiosPromise<ApplicationUserDetailsDto> {
            return localVarFp.apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingGet(options?: any): AxiosPromise<Array<ReportingUserDto>> {
            return localVarFp.apiApplicationUserReportingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingIdGet(id: number, options?: any): AxiosPromise<ReportingUserDetailsReducedDto> {
            return localVarFp.apiApplicationUserReportingIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingPost(reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any): AxiosPromise<ReportingUserDetailsDto> {
            return localVarFp.apiApplicationUserReportingPost(reportingUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserReportingPut(id?: number, reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserReportingPut(id, reportingUserRegisterDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationUserResendGet(email?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiApplicationUserResendGet(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationUserApi - object-oriented interface
 * @export
 * @class ApplicationUserApi
 * @extends {BaseAPI}
 */
export class ApplicationUserApi extends BaseAPI {
    /**
     * 
     * @param {ApplicationUserActivationDto} [applicationUserActivationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserActivatePost(applicationUserActivationDto?: ApplicationUserActivationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserActivatePost(applicationUserActivationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConfigurationDto} [configurationDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserConfigurationPost(configurationDto?: ConfigurationDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserConfigurationPost(configurationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [reportingUserId] 
     * @param {string} [selectedDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserDepositReportGet(reportingUserId?: number, selectedDate?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserDepositReportGet(reportingUserId, selectedDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdDelete(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPatch(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPatch(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdPut(id: number, applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdPut(id, applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<ApplicationUserRole>} [applicationUserRole] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserIdRolesPost(id: number, applicationUserRole?: Array<ApplicationUserRole>, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserIdRolesPost(id, applicationUserRole, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserRegisterDto} [applicationUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserPost(applicationUserRegisterDto?: ApplicationUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserPost(applicationUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserReportingGet(options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserReportingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserReportingIdGet(id: number, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserReportingIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserReportingPost(reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserReportingPost(reportingUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {ReportingUserRegisterDto} [reportingUserRegisterDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserReportingPut(id?: number, reportingUserRegisterDto?: ReportingUserRegisterDto, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserReportingPut(id, reportingUserRegisterDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationUserApi
     */
    public apiApplicationUserResendGet(email?: string, options?: any) {
        return ApplicationUserApiFp(this.configuration).apiApplicationUserResendGet(email, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet: async (returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/loginCallback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet: async (loginPageUrl?: string, returnUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (loginPageUrl !== undefined) {
                localVarQueryParameter['loginPageUrl'] = loginPageUrl;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost: async (returnUrl?: string, loginDto?: LoginDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet: async (postLogoutUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (postLogoutUrl !== undefined) {
                localVarQueryParameter['postLogoutUrl'] = postLogoutUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginCallbackGet(returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLoginPost(returnUrl, loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationLogoutGet(postLogoutUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [loginPageUrl] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {LoginDto} [loginDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [postLogoutUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginCallbackGet(returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginCallbackGet(returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [loginPageUrl] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginGet(loginPageUrl?: string, returnUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginGet(loginPageUrl, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnUrl] 
     * @param {LoginDto} [loginDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLoginPost(returnUrl?: string, loginDto?: LoginDto, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLoginPost(returnUrl, loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [postLogoutUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationLogoutGet(postLogoutUrl?: string, options?: any) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationLogoutGet(postLogoutUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogFileIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLogFileIdGet', 'id', id)
            const localVarPath = `/api/Log/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogLastIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiLogLastIdGet', 'id', id)
            const localVarPath = `/api/Log/last/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogOldGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Log/old`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogPost: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogFileIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogFileIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogLastIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogLastIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogOldGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogOldGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLogPost(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLogPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogFileIdGet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLogFileIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogGet(options?: any): AxiosPromise<Array<LogDto>> {
            return localVarFp.apiLogGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogLastIdGet(id: number, options?: any): AxiosPromise<Array<LogDto>> {
            return localVarFp.apiLogLastIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogOldGet(id?: number, options?: any): AxiosPromise<LogDetailsDto> {
            return localVarFp.apiLogOldGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLogPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLogPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public apiLogFileIdGet(id: number, options?: any) {
        return LogApiFp(this.configuration).apiLogFileIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public apiLogGet(options?: any) {
        return LogApiFp(this.configuration).apiLogGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public apiLogLastIdGet(id: number, options?: any) {
        return LogApiFp(this.configuration).apiLogLastIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public apiLogOldGet(id?: number, options?: any) {
        return LogApiFp(this.configuration).apiLogOldGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public apiLogPost(options?: any) {
        return LogApiFp(this.configuration).apiLogPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionCashRemovalReportGet: async (reportingUserId?: number, startTimeStamp?: number, endTimeStamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/cashRemovalReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (reportingUserId !== undefined) {
                localVarQueryParameter['reportingUserId'] = reportingUserId;
            }

            if (startTimeStamp !== undefined) {
                localVarQueryParameter['startTimeStamp'] = startTimeStamp;
            }

            if (endTimeStamp !== undefined) {
                localVarQueryParameter['endTimeStamp'] = endTimeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionExchangeReportGet: async (startTimeStamp?: number, endTimeStamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/exchangeReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startTimeStamp !== undefined) {
                localVarQueryParameter['startTimeStamp'] = startTimeStamp;
            }

            if (endTimeStamp !== undefined) {
                localVarQueryParameter['endTimeStamp'] = endTimeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionFilteredGet: async (startTimeStamp?: number, endTimeStamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startTimeStamp !== undefined) {
                localVarQueryParameter['startTimeStamp'] = startTimeStamp;
            }

            if (endTimeStamp !== undefined) {
                localVarQueryParameter['endTimeStamp'] = endTimeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdDelete: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTransactionIdDelete', 'id', id)
            const localVarPath = `/api/Transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdGet: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTransactionIdGet', 'id', id)
            const localVarPath = `/api/Transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdPatch: async (id: number, transactionUpdateDto?: TransactionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTransactionIdPatch', 'id', id)
            const localVarPath = `/api/Transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/merge-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdPut: async (id: number, transactionUpdateDto?: TransactionUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiTransactionIdPut', 'id', id)
            const localVarPath = `/api/Transaction/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionUpdateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionOriginalGet: async (id?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/original`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TransactionCreateDto} [transactionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionPost: async (transactionCreateDto?: TransactionCreateDto, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} reportingUserId 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionReportingUserReportingUserIdGet: async (reportingUserId: number, startTimeStamp?: number, endTimeStamp?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportingUserId' is not null or undefined
            assertParamExists('apiTransactionReportingUserReportingUserIdGet', 'reportingUserId', reportingUserId)
            const localVarPath = `/api/Transaction/reportingUser/{reportingUserId}`
                .replace(`{${"reportingUserId"}}`, encodeURIComponent(String(reportingUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startTimeStamp !== undefined) {
                localVarQueryParameter['startTimeStamp'] = startTimeStamp;
            }

            if (endTimeStamp !== undefined) {
                localVarQueryParameter['endTimeStamp'] = endTimeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [parentTransactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionSubTransactionsGet: async (parentTransactionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/subTransactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (parentTransactionId !== undefined) {
                localVarQueryParameter['parentTransactionId'] = parentTransactionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionWithdrawalReportGet: async (startTimeStamp?: number, endTimeStamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Transaction/withdrawalReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (startTimeStamp !== undefined) {
                localVarQueryParameter['startTimeStamp'] = startTimeStamp;
            }

            if (endTimeStamp !== undefined) {
                localVarQueryParameter['endTimeStamp'] = endTimeStamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionCashRemovalReportGet(reportingUserId?: number, startTimeStamp?: number, endTimeStamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionCashRemovalReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionCashRemovalReportGet(reportingUserId, startTimeStamp, endTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionExchangeReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionExchangeReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionExchangeReportGet(startTimeStamp, endTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionFilteredGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionMinimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionFilteredGet(startTimeStamp, endTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionIdDelete(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionIdGet(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionIdPatch(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionIdPatch(id, transactionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionIdPut(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionIdPut(id, transactionUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionOriginalGet(id?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionOriginalGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TransactionCreateDto} [transactionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionPost(transactionCreateDto?: TransactionCreateDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionPost(transactionCreateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} reportingUserId 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionReportingUserReportingUserIdGet(reportingUserId: number, startTimeStamp?: number, endTimeStamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionMinimalDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionReportingUserReportingUserIdGet(reportingUserId, startTimeStamp, endTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [parentTransactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionSubTransactionsGet(parentTransactionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionSubTransactionsGet(parentTransactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionWithdrawalReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionWithdrawalReportDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionWithdrawalReportGet(startTimeStamp, endTimeStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [reportingUserId] 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionCashRemovalReportGet(reportingUserId?: number, startTimeStamp?: number, endTimeStamp?: number, options?: any): AxiosPromise<Array<TransactionCashRemovalReportDto>> {
            return localVarFp.apiTransactionCashRemovalReportGet(reportingUserId, startTimeStamp, endTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionExchangeReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): AxiosPromise<Array<TransactionExchangeReportDto>> {
            return localVarFp.apiTransactionExchangeReportGet(startTimeStamp, endTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionFilteredGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): AxiosPromise<Array<TransactionMinimalDto>> {
            return localVarFp.apiTransactionFilteredGet(startTimeStamp, endTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionGet(options?: any): AxiosPromise<Array<TransactionDto>> {
            return localVarFp.apiTransactionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdDelete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiTransactionIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdGet(id: number, options?: any): AxiosPromise<TransactionDetailsDto> {
            return localVarFp.apiTransactionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdPatch(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any): AxiosPromise<TransactionDetailsDto> {
            return localVarFp.apiTransactionIdPatch(id, transactionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {TransactionUpdateDto} [transactionUpdateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionIdPut(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any): AxiosPromise<TransactionDetailsDto> {
            return localVarFp.apiTransactionIdPut(id, transactionUpdateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionOriginalGet(id?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiTransactionOriginalGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TransactionCreateDto} [transactionCreateDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionPost(transactionCreateDto?: TransactionCreateDto, options?: any): AxiosPromise<TransactionDetailsDto> {
            return localVarFp.apiTransactionPost(transactionCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} reportingUserId 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionReportingUserReportingUserIdGet(reportingUserId: number, startTimeStamp?: number, endTimeStamp?: number, options?: any): AxiosPromise<Array<TransactionMinimalDto>> {
            return localVarFp.apiTransactionReportingUserReportingUserIdGet(reportingUserId, startTimeStamp, endTimeStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [parentTransactionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionSubTransactionsGet(parentTransactionId?: number, options?: any): AxiosPromise<Array<TransactionDetailsDto>> {
            return localVarFp.apiTransactionSubTransactionsGet(parentTransactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [startTimeStamp] 
         * @param {number} [endTimeStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionWithdrawalReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any): AxiosPromise<Array<TransactionWithdrawalReportDto>> {
            return localVarFp.apiTransactionWithdrawalReportGet(startTimeStamp, endTimeStamp, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @param {number} [reportingUserId] 
     * @param {number} [startTimeStamp] 
     * @param {number} [endTimeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionCashRemovalReportGet(reportingUserId?: number, startTimeStamp?: number, endTimeStamp?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionCashRemovalReportGet(reportingUserId, startTimeStamp, endTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [startTimeStamp] 
     * @param {number} [endTimeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionExchangeReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionExchangeReportGet(startTimeStamp, endTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [startTimeStamp] 
     * @param {number} [endTimeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionFilteredGet(startTimeStamp?: number, endTimeStamp?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionFilteredGet(startTimeStamp, endTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionGet(options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionIdDelete(id: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionIdGet(id: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TransactionUpdateDto} [transactionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionIdPatch(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionIdPatch(id, transactionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {TransactionUpdateDto} [transactionUpdateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionIdPut(id: number, transactionUpdateDto?: TransactionUpdateDto, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionIdPut(id, transactionUpdateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionOriginalGet(id?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionOriginalGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TransactionCreateDto} [transactionCreateDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionPost(transactionCreateDto?: TransactionCreateDto, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionPost(transactionCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} reportingUserId 
     * @param {number} [startTimeStamp] 
     * @param {number} [endTimeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionReportingUserReportingUserIdGet(reportingUserId: number, startTimeStamp?: number, endTimeStamp?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionReportingUserReportingUserIdGet(reportingUserId, startTimeStamp, endTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [parentTransactionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionSubTransactionsGet(parentTransactionId?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionSubTransactionsGet(parentTransactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [startTimeStamp] 
     * @param {number} [endTimeStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionWithdrawalReportGet(startTimeStamp?: number, endTimeStamp?: number, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionWithdrawalReportGet(startTimeStamp, endTimeStamp, options).then((request) => request(this.axios, this.basePath));
    }
}


