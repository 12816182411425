import {BarChartOutlined, SolutionOutlined, TeamOutlined} from "@ant-design/icons";
import {Menu} from "antd";
import {NavLink} from "react-router-dom";
import {RoutingPaths} from "../../routing/routingContainer";
import styles from "./siderMenu.module.less"
import {useCurrentRoutingPath} from "digimuth-components/dist";
import {useDispatch} from "react-redux";
import {setCurrentUser} from "../../../redux/applicationUserSlice";
import {ApplicationUserApi, ApplicationUserRole} from "../../../api";
import {useCallback, useEffect, useState} from "react";
import {useAuth} from "oidc-react";
import {useInjection} from "inversify-react";
import ApiService from "../../../services/apiService";

export default function SiderMenu() {
    const route = useCurrentRoutingPath(RoutingPaths)?.route || "";
    const [hasAccess, setHasAccess] = useState(false);
    const apiService = useInjection(ApiService);
    const dispatch = useDispatch();

    const auth = useAuth();
    const updateCurrentUserInStore = useCallback(async () => {
        const response = await apiService.getApi(ApplicationUserApi)
            .apiApplicationUserIdGet(Number(auth.userData?.profile.sub));

        dispatch(setCurrentUser(response.data));
        setHasAccess((response.data.roles?.includes(ApplicationUserRole.Admin) || response.data.roles?.includes(ApplicationUserRole.DeviceAdmin)) ?? false);
    }, [auth.userData, apiService, dispatch]);

    useEffect(() => {
        updateCurrentUserInStore();
    }, [updateCurrentUserInStore]);

    return <>
        <NavLink to={"/"}>
            <img className={styles.logo} src="/talemax-logo.png" alt="Logo Talemax"/>
        </NavLink>

        <Menu theme="dark" selectedKeys={[route]} mode="inline">

            {hasAccess ?
                <Menu.Item key={RoutingPaths.users.route} icon={<TeamOutlined/>}>
                    <NavLink to={RoutingPaths.users.route}>
                        Lista użytkowników
                    </NavLink>
                </Menu.Item>
                : null}

            <Menu.Item key={RoutingPaths.reportingUsers.route} icon={<SolutionOutlined/>}>
                <NavLink to={RoutingPaths.reportingUsers.route}>
                    Urządzenia
                </NavLink>
            </Menu.Item>

            <Menu.Item key={RoutingPaths.transactions.route} icon={<BarChartOutlined/>}>
                <NavLink to={RoutingPaths.transactions.route}>
                    Wszystkie transakcje
                </NavLink>
            </Menu.Item>

        </Menu>
    </>;
}
